import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

const MobileSorghum = () => {
  const { t } = useTranslation()
  return (
    <div className="block md:hidden">
      <div className="flex justify-center">
        <StaticImage
          src="../../images/Mobile/05.蒜頭酒去背照2.png"
          width={280}
          alt="凍蒜高粱"
          placeholder="blurred"
          style={{ margin: "0 auto" }}
        />
      </div>
      <div className="px-7 pt-4 pb-16">
        <h2 className="text-gold mb-3">{t("sorghum.title1")}</h2>
        <h1 className="text-3xl font-normal" style={{ letterSpacing: "0.3em" }}>
          {t("sorghum.title2")}
        </h1>
        <div className="my-6 bg-gold w-full" style={{ height: 1 }}></div>
        <p className="pb-16" style={{ letterSpacing: "0.2em" }}>
          {t("sorghum.title3")}
        </p>
        <div className="mb-6 bg-gold" style={{ width: 100, height: 1 }}></div>
        <p className="" style={{ letterSpacing: "0.2em" }}>
          ABV:45%
        </p>
        <p className="" style={{ letterSpacing: "0.2em" }}>
          Volume：700ml
        </p>
        <p className="mb-12" style={{ letterSpacing: "0.2em" }}>
          {t("sorghum.title4")}
        </p>
        <h3 className="text-lg">{t("sorghum.title5")}</h3>
        <h3 className="text-lg text-gold mb-5">NT $2280</h3>
        <Link to="/location" className="text-gold font-light text-sm underline">
          {t("sorghum.link1")}
        </Link>
      </div>
      <div
        className="py-16 px-7 flex flex-col justify-center items-center"
        style={{ background: "#F3F3F3" }}
      >
        <StaticImage
          src="../../images/Desktop/05.LOGO_Gold@2x.png"
          width={76.88}
          alt="gold-logo"
          className="mb-4"
          placeholder="blurred"
        />
        <h3
          className="font-normal text-lg mb-12"
          style={{ letterSpacing: "0.4em" }}
        >
          {t("sorghum.title6")}
        </h3>
        <StaticImage
          src="../../images/Desktop/05.banner_1.png"
          alt="gold-logo"
          className="w-full mb-7"
          placeholder="blurred"
        />
        <h3
          className="font-normal text-lg mb-5"
          style={{ letterSpacing: "0.4em" }}
        >
          {t("sorghum.title7")}
        </h3>
        <p className="text-base mb-12" style={{ letterSpacing: "0.2em" }}>
          {t("sorghum.title8")}
        </p>
        <StaticImage
          src="../../images/Desktop/05.banner_2.png"
          alt="gold-logo"
          className="w-full mb-7"
          placeholder="blurred"
        />
        <h3
          className="font-normal text-lg mb-5"
          style={{ letterSpacing: "0.4em" }}
        >
          {t("sorghum.title9")}
        </h3>
        <p className="text-base mb-24" style={{ letterSpacing: "0.2em" }}>
          {t("sorghum.title10")}
        </p>
        <StaticImage
          src="../../images/Desktop/05.LOGO_garlic@2x.png"
          width={73}
          alt="garlic-remove-bg"
          className="mb-5"
          placeholder="blurred"
        />
        <h3
          className="font-normal text-lg mb-12"
          style={{ letterSpacing: "0.4em" }}
        >
          {t("sorghum.title11")}
        </h3>
        <StaticImage
          src="../../images/Mobile/05.banner_3.png"
          alt="recommend"
          className="w-full mb-5"
          placeholder="blurred"
        />
        <h3 className="font-normal text-lg" style={{ letterSpacing: "0.4em" }}>
          {t("sorghum.title12")}
        </h3>
        <div className="my-5 bg-gold" style={{ height: 1, width: 100 }}></div>
        <p className="text-base mb-20" style={{ letterSpacing: "0.2em" }}>
          {t("sorghum.title13")}
        </p>
        <StaticImage
          src="../../images/Mobile/05.banner_4.png"
          alt="drink"
          className="w-full mb-5"
          placeholder="blurred"
        />
        <h3 className="font-normal text-lg" style={{ letterSpacing: "0.4em" }}>
          {t("sorghum.title14")}
        </h3>
        <div className="my-5 bg-gold" style={{ height: 1, width: 100 }}></div>
        <p className="text-base mb-20" style={{ letterSpacing: "0.2em" }}>
          {t("sorghum.title15")}
        </p>
      </div>
    </div>
  )
}

export default MobileSorghum
