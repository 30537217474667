import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MobileSorghum from "../components/MobileSorghum"
import DesktopSorghum from "../components/DesktopSorghum"

const Sorghum = () => (
  <>
    <Seo
      title="中式白酒-凍蒜高粱"
      description="圓融醇味讓舌尖被柔順細膩風味包覆，尾韻香氣迴盪於唇齒之中層層湧出。大蒜被享譽為最佳「養顏聖品」，有解毒、養顏、活血、提神功效。品飲1:4完美比例，凍飲香氣層層湧出！"
    />
    <DesktopSorghum />
    <MobileSorghum />
  </>
)

export default Sorghum
