import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

const DesktopSorghum = () => {
  const { t } = useTranslation()

  return (
    <div className="hidden md:block">
      <div className="flex pl-60 pt-24 pr-44 pb-9">
        <StaticImage
          src="../../images/Desktop/05.garlic_remove_bg.png"
          width={404}
          alt="凍蒜高粱"
          className="mr-24"
          placeholder="blurred"
        />
        <div>
          <h2 className="text-gold mb-3 text-2xl tracking-large font-medium">
            {t("sorghum.title1")}
          </h2>
          <h1
            className="text-5xl font-normal"
            style={{ letterSpacing: "0.3em" }}
          >
            {t("sorghum.title2")}
          </h1>
          <div className="my-6 bg-gold w-full" style={{ height: 2 }}></div>
          <p className="pb-16 text-sm" style={{ letterSpacing: "0.2em" }}>
            {t("sorghum.title3")}
          </p>
          <div className="mb-6 bg-gold" style={{ width: 100, height: 1 }}></div>
          <p className="text-sm" style={{ letterSpacing: "0.2em" }}>
            ABV:45%
          </p>
          <p className="text-sm" style={{ letterSpacing: "0.2em" }}>
            Volume：700ml
          </p>
          <p className="text-sm mb-12" style={{ letterSpacing: "0.2em" }}>
            {t("sorghum.title4")}
          </p>
          <h3 className="text-lg" style={{ letterSpacing: "0.3em" }}>
            {t("sorghum.title5")}
          </h3>
          <h3
            className="text-lg text-gold mb-5"
            style={{ letterSpacing: "0.3em" }}
          >
            NT $2280
          </h3>
          <Link
            to="/location"
            className="text-gold font-light text-sm underline"
            style={{ letterSpacing: "0.4em" }}
          >
            {t("sorghum.link1")}
          </Link>
        </div>
      </div>
      <div className="px-28 pt-16 pb-24" style={{ background: "#F3F3F3" }}>
        <div className="flex flex-col justify-center items-center mb-24">
          <StaticImage
            src="../../images/Desktop/05.LOGO_Gold@2x.png"
            width={76.88}
            alt="gold-logo"
            className="mb-4"
            placeholder="blurred"
          />
          <h3
            className="font-normal text-lg"
            style={{ letterSpacing: "0.4em" }}
          >
            {t("sorghum.title6")}
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-20 mb-12">
          <div>
            <StaticImage
              src="../../images/Desktop/05.banner_1.png"
              alt="gold-logo"
              className="mb-4"
              placeholder="blurred"
            />
          </div>
          <div>
            <h3
              className="font-normal mb-5"
              style={{ letterSpacing: "0.4em", fontSize: 28 }}
            >
              {t("sorghum.title7")}
            </h3>
            <p className="text-base" style={{ letterSpacing: "0.2em" }}>
              {t("sorghum.title8")}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-20 mb-14">
          <div className="flex flex-col justify-center items-start">
            <h3
              className="font-normal mb-5"
              style={{ letterSpacing: "0.4em", fontSize: 28 }}
            >
              {t("sorghum.title9")}
            </h3>
            <p className="text-base" style={{ letterSpacing: "0.2em" }}>
              {t("sorghum.title10")}
            </p>
          </div>
          <div>
            <StaticImage
              src="../../images/Desktop/05.banner_2.png"
              alt="banner_2"
              className="mb-4"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mb-24">
          <StaticImage
            src="../../images/Desktop/05.LOGO_garlic@2x.png"
            width={73}
            alt="gold-logo"
            className="mb-4"
            placeholder="blurred"
          />
          <h3
            className="font-normal text-lg"
            style={{ letterSpacing: "0.4em" }}
          >
            {t("sorghum.title11")}
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-20 mb-14">
          <div className="flex flex-col justify-center items-center">
            <StaticImage
              src="../../images/Desktop/05.banner_3.png"
              width={500}
              alt="banner_3"
              className="mb-10"
              placeholder="blurred"
            />
            <div className="flex flex-col justify-center items-center w-full">
              <h3
                className="font-normal text-2xl"
                style={{ letterSpacing: "0.4em" }}
              >
                {t("sorghum.title12")}
              </h3>
              <div
                className="my-5 bg-gold"
                style={{ height: 1, width: 100 }}
              ></div>
            </div>
            <p
              className="font-light text-base w-3/4"
              style={{ letterSpacing: "0.3em" }}
            >
              {t("sorghum.title13")}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <StaticImage
              src="../../images/Desktop/05.banner_4.png"
              alt="banner_4"
              className="mb-10"
              placeholder="blurred"
              width={500}
            />
            <div className="flex flex-col justify-center items-center w-full">
              <h3
                className="font-normal text-2xl"
                style={{ letterSpacing: "0.4em" }}
              >
                {t("sorghum.title14")}
              </h3>
              <div
                className="my-5 bg-gold"
                style={{ height: 1, width: 100 }}
              ></div>
            </div>
            <p
              className="font-light text-base w-3/4"
              style={{ letterSpacing: "0.3em" }}
            >
              {t("sorghum.title15")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopSorghum
